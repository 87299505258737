<template>
  <div class="problem">
    <ShopNavBar title="常见问题" />

    <van-collapse v-model="activeNames">
      <van-collapse-item :title="item.question" :name="item.id" v-for="item in list" :key="item.id">
        {{ item.answer }}
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  setup() {
    return {
      us: ref(null),
      activeNames: ref([]),
      list: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Help/List",
        {
          userid: _this.us.userid
        },
        (res) => {
          console.log(res)
          if(res.data.code == 100) {
            let data = res.data.data
            _this.list = data
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.problem {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}
</style>